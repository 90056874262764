import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class SnackBarService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public showSnackBar(
    message: string,
    variant: 'success' | 'error' | 'info' = 'success',
    noDuration = false,
  ): void {
    let additionalData: any;

    if (noDuration) {
      additionalData = {
        preClose: () => {
          this.snackBar.dismiss();
        },
      };
    }

    this.snackBar.open(message, 'x', {
      duration: 3000,
      panelClass: [`snackbar--${variant}`],
      data: additionalData || null,
    });
  }
}
